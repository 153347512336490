import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { PrivacyPolicyQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription,BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/privacypolicy/Privacy-Policy-Security.jpg";
// import company_overview_image from "../../images/siteImages/companyProfile/WHO_WE_ARE.png";
// import quick_account_opening from "../../images/siteImages/companyProfile/QUICK_ACCOUNT_OPENING.png";
// import trading_experiance from "../../images/siteImages/companyProfile/TRADING_EXPERIANCE.png";
// import easy_to_use from "../../images/siteImages/companyProfile/EASY_TO_USE.png";
// import mt4_platform from "../../images/siteImages/companyProfile/MT4_PLATFORM.png";
// import deposits from "../../images/siteImages/companyProfile/DEPOSITS.png";
// import wire_transfer from "../../images/siteImages/companyProfile/WIRE_TRANSFER.png";
// import visa from "../../images/siteImages/companyProfile/VISA.png";
// import netler from "../../images/siteImages/companyProfile/NETLER.png";
// import skrill from "../../images/siteImages/companyProfile/SKRILL.png";



const PrivacyPolicy = () => {
  const language = useSelector((state) => state.language);
  console.log("language",language);
   const privacyPolicyData = PrivacyPolicyQuery(language);
   console.log(".....",privacyPolicyData);
  // // const { seo_page_title, seo_page_description, seo_page_keywords } =
  // //   privacyPolicyData;
  const {
    privacy_policy_heading,
    privacy_policy_body,
  } = privacyPolicyData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
   
    <Layout >
    <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-100 p-bottom-110 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
            <h2 className="pt-5 mt-5 text-bold-md text-black">
                {privacy_policy_heading.text}
              </h2>
            <p className="text-gray mt-5">{privacy_policy_body.text}</p>
            </Col>
          </Row>
        </Container>
      </section>

      <Subscription />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(PrivacyPolicy);
